<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row" id="article">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-primary d-flex justify-content-center"
                >
                  <template v-slot:header>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h5 class="my-0 text-primary d-flex">
                        <i class="ri-chat-upload-fill mr-1"></i>Upload Bulk
                      </h5>
                      <a
                        href="/sample_article.xlsx"
                        download
                        class="btn btn-primary btn-sm"
                      >
                        <i class="fa fa-download mr-1"></i> Download Sample File
                      </a>
                    </div>
                  </template>
                  <h5 class="card-title mt-0">Excel File:</h5>
                  <b-form-group
                    class="mt-2"
                    id="input-group-1"
                    label="Upload Excel File Here [Upload only .csv and .xlsx]"
                    label-for="input-1"
                  >
                    <b-form-file
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      id="input-1"
                      placeholder="Choose a file or drop it here..."
                      ref="file"
                    ></b-form-file>
                  </b-form-group>
                  <b-button
                    type="submit"
                    variant="success"
                    class="mr-2"
                    @click.prevent="fetchExcel"
                    >Upload</b-button
                  >
                </b-card>
              </div>
            </div>
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="form-inline navbar-search">
                  <div class="input-group">
                    <input
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search..."
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      value=""
                      v-model="filter"
                      v-on:keyup="search"
                    />
                    <div class="input-group-append">
                      <button
                        id="myBtn"
                        class="btn btn-search"
                        @click="searchFor"
                      >
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="all-tabs">
                  <div
                    :class="activeTab == 'all' ? ' tab-options' : ''"
                    @click.prevent="fetchData('all')"
                    class="mx-2 opt"
                  >
                    All
                  </div>
                  |
                  <div
                    :class="activeTab == 'trash' ? ' tab-options' : ''"
                    @click.prevent="fetchData('trash')"
                    class="mx-2 opt"
                  >
                    Trash
                  </div>
                  |
                  <div
                    :class="activeTab == 'active' ? ' tab-options' : ''"
                    @click.prevent="fetchData('active')"
                    class="mx-2 opt"
                  >
                    Active
                  </div>
                  |
                  <div
                    :class="activeTab == 'brand_material' ? ' tab-options' : ''"
                    @click.prevent="fetchData('brand_material')"
                    class="mx-2 opt"
                  >
                    Brand Materials
                  </div>
                  <template v-if="can('add-article')">
                    <div class="d-flex align-items-center">
                      <svg
                        class="svg-inline--fa fa-table mx-2"
                        height="16px"
                        width="16px"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fa"
                        data-icon="table"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"
                        ></path>
                      </svg>
                      <router-link :to="{ name: 'add-article' }" class="opt"
                        >Add Article</router-link
                      >
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                striped
                bordered
                :items="tableData.data"
                :tbody-tr-class="rowClass"
                :fields="fields"
                :sort-by.sync="sortBy"
                :per-page="0"
                :current-page="currentPage"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
                :key="key"
              >
                <template
                  v-can="'update-article-status'"
                  v-slot:cell(is_active)="row"
                >
                  <b-form-checkbox
                    switch
                    class="mb-1"
                    @change="updateStatus(row.item.id)"
                    :checked="row.item.is_active == 1 ? true : false"
                  >
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(article_schedule)="row">
                  <b-form-checkbox
                    switch
                    class="mb-1"
                    :checked="row.item.article_schedule == 1 ? true : false"
                  >
                  </b-form-checkbox>
                </template>
                <template v-can="'edit-article'" v-slot:cell(edit)="row">
                  <router-link
                    :to="{
                      name: 'edit-article',
                      params: { id: row.item.id },
                    }"
                  >
                    <i class="fa fa-edit edit-i"></i>
                  </router-link>
                </template>
                <template
                  v-if="fields[fields.length - 1].key == 'delete'"
                  v-slot:cell(delete)="row"
                  v-can="'delete-article'"
                >
                  <div
                    v-if="activeTab != 'trash'"
                    @click.prevent="deleteArticle(row.item.id)"
                  >
                    <i class="mdi mdi-delete delete-i"></i>
                  </div>
                </template>
                <template
                  v-else
                  v-slot:cell(restore)="row"
                  v-can="'restore-article'"
                >
                  <div
                    v-if="activeTab == 'trash'"
                    @click.prevent="restoreArticle(row.item.id)"
                  >
                    <i class="mdi mdi-restore restore-i"></i>
                  </div>
                </template>
                <template v-slot:cell(repost)="row">
                  <img src="../../../../public/repost.svg" class="article-action-icon restore-i" @click="openRepostModal(row.item)">
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    ></b-pagination>
                  </ul>
                </div>
                <div class="form-inline navbar-search">
                  <span class="mr-2">Go to a Particular Paginated Page :</span>
                  <div class="input-group">
                    <b-form-input
                      id="case"
                      v-model="pageNo"
                      placeholder="Enter Page No"
                      class="in-width"
                    >
                    </b-form-input>
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="filterPage">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <b-modal
          id="bvRepostModal"
          ref="modal"
          title="Repost the Content"
          @ok="handleRepostSubmit"
          @cancel="closeRepostModal"
          v-model="showRepostModal"
          no-close-on-backdrop centered
          hide-header-close
          ok-title="Ok. Continue"
          cancel-title="No"
        >
          <div>Reposting will change the creation date of the content.</div>
          <div class="pt-2">Do you want to continue ?</div>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import articleMixin from "../../../mixins/ModuleJs/article";

export default {
  mixins: [MixinRequest, articleMixin],
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Article Management",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
};
</script>
